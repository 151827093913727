import { Suspense, lazy } from "react";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "../src/App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
const Home = lazy(() => import("./pages/Home"));
const Faq = lazy(() => import("./pages/Faq"));
const Leasing = lazy(() => import("./pages/Leasing"));
const Service = lazy(() => import("./pages/Services"));
const ProductList = lazy(() => import("./pages/ProductList"));
const ProductCheckout = lazy(() => import("./pages/ProductCheckout"));
const SavingsCalculator = lazy(() => import("./pages/SavingsCalculator"));
const Spares = lazy(() => import("./pages/Spares"));
const Accessories = lazy(() => import("./pages/Accessories"));
const Cancellation = lazy(() => import("./pages/Cancellation"));
const Terms = lazy(() => import("./pages/Terms"));
const Privacy = lazy(() => import("./pages/Privacy"));
const Partner = lazy(() => import("./pages/Partner"));
const Login = lazy(() => import("./pages/Login"));
const ServiceDetails = lazy(() => import("./pages/ServiceDetails"));
const UsedProducts = lazy(() => import("./pages/Used"));
// const Product = lazy(() => import("./pages/Product"));
const Blog = lazy(() => import("./pages/blog/Blog"));
const BlogDetails = lazy(() => import("./pages/blog/BlogDetails"));
const About = lazy(() => import("./pages/About"));
const Contact = lazy(() => import("./pages/Contact"));
const CalculatorComing = lazy(() => import("./pages/CalculatorComing"));
const NotFound = lazy(() => import("./pages/NotFound"));
const Clients = lazy(() => import("./pages/Clients"));
const RiskDeclaration = lazy(() => import("./pages/RiskDeclaration.js"));
const App = () => {
  return (
    <Router>
      <ScrollToTop />
      <Suspense
        fallback={
          <div className="flone-preloader-wrapper">
            <div className="flone-preloader">
              <span></span>
              <span></span>
            </div>
          </div>
        }
      >
        <Routes>
          <Route path={process.env.PUBLIC_URL + "/"} element={<Home />} />
          <Route path={process.env.PUBLIC_URL + "/faq"} element={<Faq />} />
          <Route
            path={process.env.PUBLIC_URL + "/leasing"}
            element={<Leasing />}
          />
          <Route
            path={process.env.PUBLIC_URL + "/services"}
            element={<Service />}
          />
          <Route
            path={process.env.PUBLIC_URL + "/products"}
            element={<ProductList />}
          />
          <Route
            path={process.env.PUBLIC_URL + "/spares"}
            element={<Spares />}
          />
          <Route
            path={process.env.PUBLIC_URL + "/accessories"}
            element={<Accessories />}
          />
          <Route
            path={process.env.PUBLIC_URL + "/cancellation-refund"}
            element={<Cancellation />}
          />
          <Route
            path={process.env.PUBLIC_URL + "/terms-and-conditions"}
            element={<Terms />}
          />
          <Route
            path={process.env.PUBLIC_URL + "/privacy-policy"}
            element={<Privacy />}
          />
          <Route
            path={process.env.PUBLIC_URL + "/partner"}
            element={<Partner />}
          />
          <Route path={process.env.PUBLIC_URL + "/login"} element={<Login />} />
          <Route
            path={process.env.PUBLIC_URL + "/used"}
            element={<UsedProducts />}
          />
          <Route
            path={process.env.PUBLIC_URL + "/product-checkout/:title"}
            element={<ProductCheckout />}
          />
          <Route
            path={process.env.PUBLIC_URL + "/insights"}
            element={<Blog />}
          />
          <Route
            path={process.env.PUBLIC_URL + "/risk-declaration"}
            element={<RiskDeclaration />}
          />
          <Route
            path={process.env.PUBLIC_URL + "/insights-details/:id"}
            element={<BlogDetails />}
          />
          <Route path={process.env.PUBLIC_URL + "/about"} element={<About />} />
          <Route
            path={process.env.PUBLIC_URL + "/contact"}
            element={<Contact />}
          />
          <Route
            path={process.env.PUBLIC_URL + "/calculations"}
            element={<SavingsCalculator />}
          />
          <Route
            path={process.env.PUBLIC_URL + "/service-details"}
            element={<ServiceDetails />}
          />
          <Route
            path={process.env.PUBLIC_URL + "/calculator-coming"}
            element={<CalculatorComing />}
          />
          <Route
            path={process.env.PUBLIC_URL + "/clients"}
            element={<Clients />}
          />
          <Route
            path={process.env.PUBLIC_URL + "/service-details/:serviceSlug"}
            element={<ServiceDetails />}
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>
    </Router>
  );
};

export default App;
